body {
  margin: 0;
  padding: 0;
  font-family: 'futura-pt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

a {
  color: #777;
}

a:hover {
  color: black;
}

html,
body,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn,
.form-control {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 4px;
}

.btn-primary {
  background-color: #c5a231;
  background: linear-gradient(180deg, #c5a231 0%, #dbbb55 100%);
  border-color: #c4bb53;
  /* font-weight: 500; */
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-link {
  color: rgb(0, 0, 0);
}

.btn-link:hover {
  color: rgb(0, 0, 0);
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.btn-primary:hover {
  background-color: #c5a231;
  background: linear-gradient(180deg, #c5a231 0%, #dbbb55 100%);
  border-color: #c4bb53;
  opacity: 0.8;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #c5a231;
  border-color: #c4bb53;
}

.btn-primary:disabled,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:active {
  background-color: #d3d3d3;
  background: #d3d3d3;
  border-color: #d3d3d3;
}

.modal-title.h4 {
  color: black;
}

.select-language {
  display: inline;
  width: auto;
  height: calc(1em + 0.75rem + 2px);
  padding: 0.175rem 0.15rem;
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 18px;
}

.btn-primary:focus,
.btn-secondary:focus,
.btn-primary:active,
.btn-secondary:active,
.btn-secondary:visited {
  box-shadow: none !important;
  border: none !important;
}
